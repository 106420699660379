import React from "react";
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from '../utils/web3React'
import { ThemeProvider } from "styled-components";
import ModalsProvider from "../contexts/Modals";
import TransactionProvider from '../contexts/Transactions'

import { BrowserRouter as Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { Grommet } from 'grommet'
import { grommetTheme } from '../styles/grommetTheme';
import { styledComponentsTheme } from '../styles/styledComponentsTheme';
import { GlobalStyles } from '../styles/GlobalStyles';
import { Footer } from '../components/Footer';
import { Routes as RoutedContent } from './Routes';
import { networkID, chainConfig } from "../constants";

export const history = createBrowserHistory();


const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Grommet theme={grommetTheme}>
        <ThemeProvider theme={styledComponentsTheme}>
          <TransactionProvider>
            <ModalsProvider>{children}</ModalsProvider>
          </TransactionProvider>
        </ThemeProvider>
      </Grommet>
    </Web3ReactProvider>
  );
};

const App: React.FC = () => {
  return (
    <Providers>
      <Router>
        <GlobalStyles />
        <RoutedContent />
        <Footer />
      </Router>
    </Providers>
  );
};

export default App;
