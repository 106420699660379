import React from 'react';
import styled from 'styled-components';
// @ts-ignore

import { Text } from '../../components/Text';

import useMobileCheck from '../../hooks/useMobileCheck';


import { Heading } from '../../components/Heading';
import Spacer from "../../components/Spacer";



import RoadmapTitleSVG from '../../assets/svg/roadmap-title.svg';
import RoadmapSVG from '../../assets/svg/roadmap.svg';
import PatternconfettiJPG from '../../assets/images/pattern-waves-small.jpg';

import Partnerlogo1PNG from '../../assets/images/partner-logo-1.png';
import Partnerlogo2PNG from '../../assets/images/partner-logo-2.png';
import Partnerlogo3PNG from '../../assets/images/partner-logo-3.png';
import Partnerlogo4PNG from '../../assets/images/partner-logo-4.png';
import Partnerlogo5PNG from '../../assets/images/partner-logo-5.png';
import Partnerlogo6PNG from '../../assets/images/partner-logo-6.png';
import Partnerlogo7PNG from '../../assets/images/partner-logo-7.png';
import Partnerlogo8PNG from '../../assets/images/partner-logo-8.png';
import Partnerlogo9PNG from '../../assets/images/partner-logo-9.png';
import Partnerlogo10PNG from '../../assets/images/partner-logo-10.png';


interface mobile {
  isMobile: boolean;
}

const RainbowBackground = styled.div`
  min-width: 100%;
  overflow: hidden;
  min-height: ${(p: mobile) => p.isMobile && 'calc(100vh - 160px)'};
`;
const MainContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${p => p.theme.screenSizes.largest}) {
    padding: ${(p: mobile) => (p.isMobile ? '30px' : '30px')};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const InfoContainer = styled.div`
  position: relative;
  max-width: ${p => p.theme.screenSizes.largest};
  min-width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
  }
`;


const StyledPartnerContainer = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  justify-content: center;
  width: 100%;
  display: flex;
  
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
`;

const StyledPartnerItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  width: calc(25% - 32px);
  height: auto;
  flex-wrap: wrap;
  min-width: 258px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    min-width: 139px;
    width: calc(50% - 18px);
    margin: 9px;
  }
`;

const StyledPartnerItemImg = styled.img`
  height: 100%;
  width: 100%;
  max-width: 200.24px;
  max-height: 80px;
`;

//
// Main component

export const Parnter = () => {
  const isSmallScreen = useMobileCheck('800px');
  const isMediumScreen = useMobileCheck('1080px');
  const m: boolean = (window as any).mobileCheck();
  return (
    <RainbowBackground isMobile={m}>
      <MainContainer isMobile={m}>
            <ContentContainer className={m ? undefined : 'pt2'}>
                <InfoContainer>
                    <Heading
                        level={ 2}
                        size={'medium'}
                        className="my25"
                        color='#f8f8f0'
                      >
                        Partners and Investors
                      </Heading>
                      <StyledPartnerContainer>
                        <StyledPartnerItem>
                          <a href="https://valkyriefund.xyz/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo1PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="https://decil.org/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo2PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="http://fidelabs.org/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo3PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="https://fantacore.games/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo7PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="https://ethereum.org" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo10PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="https://polygon.technology/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo4PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="https://www.binance.com/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo5PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="https://seelen.pro/" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo6PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo8PNG}/>
                          </a>
                        </StyledPartnerItem>
                        <StyledPartnerItem>
                          <a href="" target="_blank">
                            <StyledPartnerItemImg src= {Partnerlogo9PNG}/>
                          </a>
                        </StyledPartnerItem>
                      </StyledPartnerContainer>
                </InfoContainer>
            </ContentContainer>
      </MainContainer>
    </RainbowBackground>
  );
};
