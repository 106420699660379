import React from 'react';
import { HashLink as Anchor } from 'react-router-hash-link';
import styled from 'styled-components';
import { Link } from '../../components/Link';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { isMobile } from 'react-device-detect'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import InfoShowPNG from '../../assets/images/everisland.jpg';
import InfoMaskPNG from '../../assets/images/header-mask.png';

import LogoPNG from '../../assets/images/island-logo.png';

import RuleSVG from '../../assets/svg/rule-light-green.svg';

import { Games } from './Games';
import { Info } from './Info';
import { Roadmap } from './Roadmap';
import { Parnter } from './Parnter';
import { GameDetail } from './GameDetail';
import { Mint } from './Mint';

const StyledSessionHead = styled.div`
  min-height: 48rem;
  height: 100vh-10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const StyledBackground = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-image: url(${InfoShowPNG});
    background-size: cover;
    background-position: center;
    mask-image:  url(${InfoMaskPNG});
    mask-size: 100% 100%;
    @media screen and (max-width: 800px) {
        mask-image: none;
    }
`;

const StyledRuleGreen = styled.div`
    
    position: relative;
    padding: 26px;
    background-image: url(${RuleSVG});
    background-size: auto auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;

`;

const StyledLogoImg = styled.img`

  position: absolute;
  z-index: 0;
  max-width: 40rem;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    max-width: 300px;
  }
`;

const StyledSessionMain = styled.div`
  margin-top: -200px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${p => p.theme.screenSizes.largest};

  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
`;

const StyledSessionInfo = styled.div`
  margin-top: -200px;

  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
  // background-color:  #245C8F;
  padding:  30px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    margin-top: -450px;
    padding:  10px;
  }
`;

const StyledSessionRoadmap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
  // background-color:  #DBE9B6;
`;

const StyledSessionParnter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
  // background-color:  #245C8F;
`;

const StyledSessionGameDetail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
  // background-color:  #245C8F;
`;

const StyledSessionMint = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
  // background-color:  #245C8F;
`;

const particlesInit = async (main) => {
  console.log(main);

  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  // starting from v2 you can add only the features you need reducing the bundle size
  await loadFull(main);
};


export const HomePage = () => {
  const m: boolean = (window as any).mobileCheck();
  return (
    <PageTemplate
      title={'EverIsland'}
      description={'EverIsland is a most fantastic Metaverse Game'}
    >
      <StyledSessionHead>
        <StyledBackground>
        {m?<div/>:<Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            "fpsLimit": 120,
            "particles": {
                "number": {
                    "value": 60,
                    "density": {
                        "enable": false
                    }
                },
                "color": {
                  "value": ["#F8F8F0","#FFC326",  "#19ad5e", "#46C0FF"],
                },
                "size": {
                    "value": 3,
                    "random": true,
                    "anim": {
                        "speed": 4,
                        "size_min": 0.5
                    }
                },
                "line_linked": {
                    "enable": false
                },
                "move": {
                    "enable": true,
                    "random": true,
                    "speed": 1,
                    "direction": "top",
                    "out_mode": "out"
                }
            },
            "interactivity": {
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "bubble"
                    },
                    "onclick": {
                        "enable": false,
                        "mode": "repulse"
                    }
                },
                "modes": {
                    "bubble": {
                        "distance": 250,
                        "duration": 2,
                        "size": 0,
                        "opacity": 0
                    },
                    "repulse": {
                        "distance": 800,
                        "duration": 4
                    }
                }
            }
        }} />}
        </StyledBackground>
        <StyledLogoImg src={LogoPNG} alt="Welcome to EverIsland"/>
        
      </StyledSessionHead>
      { /* <StyledSessionMain>
        <Games />
    </StyledSessionMain> */ }
      <StyledSessionInfo>
      { /* <StyledRuleGreen/> */ }
        <Info />
      </StyledSessionInfo>
      <StyledSessionMint>
        <Mint/>
      </StyledSessionMint>
      <StyledSessionGameDetail>
        <GameDetail/>
      </StyledSessionGameDetail>
      <StyledSessionRoadmap>
        <Roadmap/>
      </StyledSessionRoadmap>
      <StyledSessionParnter>
        <Parnter/>
      </StyledSessionParnter>
      
    </PageTemplate>
  );
};

export default HomePage;