import React from 'react';
import styled, { keyframes } from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppBar } from './AppBar';
import { Heading } from './Heading';
import { Helmet } from 'react-helmet';

import BackgroundJPG from '../assets/images/pattern-leaves-white.jpg';

const Content = styled.div`
  width: 100%;
  margin: 0 0;
  position: relative;
  margin-top: 0;
`;

const Gutter = styled.div`
  padding: 0 0px 0; 
  display: flex;
  justify-content: center;
`;

const RainbowBackground = styled.div`
  background-image: ${p =>
    `radial-gradient(circle at 100% -80%, ${p.theme.rainbowLight})`};
  min-height: 100vh;
`;

export const animleaveswhite = () => keyframes`
  from { background-position: 0 0; }
  to { background-position: 100% 0; }  
`

const RainbowBackground2 = styled.div`
  // background-image: linear-gradient(rgba(240, 240, 240, .7), rgba(240, 240, 240, .7)),url(${BackgroundJPG});
  background-image: ${p =>
    `radial-gradient(circle at 100% -80%, ${p.theme.rainbowLight})`};
  // background-size: 420px 420px;
  height: calc(100% + 420px);
  width: calc(100%);
  // animation: ${animleaveswhite} 80s linear 0s infinite forwards
`;

interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  title: string;
  header?: string;
  description?: string;
  history: any;
}

const _PageTemplate = ({
  children,
  description,
  title,
  header = title,
}: Props): JSX.Element => {
  return (
    <RainbowBackground>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="twitter:description" content={description} />
      </Helmet>
      <AppBar />
      <Gutter>
        <Content>
          {children}
        </Content>
      </Gutter>
    </RainbowBackground>
  );
};

export const PageTemplate = withRouter(_PageTemplate);
