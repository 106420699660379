import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Card,CardBody,Select,Box } from 'grommet';

import { Text } from '../../components/Text';

import useMobileCheck from '../../hooks/useMobileCheck';


import { Heading } from 'grommet';
import Spacer from "../../components/Spacer";
// import { Button } from '../../components/Button';

import { PageTemplate } from '../../components/PageTemplate';
import DotsPNG from '../../assets/images/pattern-dots.png';
import LockSVG from '../../assets/svg/lock.svg';
import IslandNftJPG from '../../assets/images/islandnft.jpg';

import NftsJPG from '../../assets/images/nfts.jpg';

import IslandPNG from '../../assets/images/island.jpg';
import FarmersPNG from '../../assets/images/farmers.jpg';
import ToolPetPNG from '../../assets/images/tool&pet.jpg';


interface mobile {
  isMobile: boolean;
}

const RainbowBackground = styled.div`
  min-width: 100%;
  overflow: hidden;
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  padding-top: 60px;
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto 5%;

`;

const HeadContainer = styled.div`

  padding-top: 80px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    padding: 6px;

  }
`;

const StyledHeadLeft = styled.div`
  width: 46%;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    width: 100%;
  }
`;

const StyledHeadRight = styled.div`
  width: 46%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    width: 100%;

  }
`;

const StyledNftImgNode = styled.img`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border-radius: 8px;
  max-width: 485px;
  max-height: 485px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    max-width: 95%;
    max-height: 95%;

  }

  
`;

const ContentContainer = styled.div`

  padding-top: 80px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 8px;
  column-gap: 8px;
`;

const StyledCardBody = styled(CardBody)`
  background-image: url(${DotsPNG});
  background-size: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
`;

const StyledDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledItemTitle = styled.p`
  font-family: 'BoldenVan', Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-top: 36px;
  margin-bottom: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #f8f8f0;

`;

const StyledItemContent = styled.p`
  font-family: 'Lufga';
  font-size: 16px;
  font-style: normal;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: #d7def9;
  max-width: 365px;
  margin-bottom: 40px;
`



const StyledPartnerItemImg = styled.img`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border-radius: 8px;
  max-width: 365px;
  max-height: 365px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    max-width: 95%;
    max-height: 95%;

  }

`;

const StyledRibbonText = styled.span`
  height: 20px;
  background-color: #fff;
  position: relative;
  left: 8px;
  top: -210px;
  width: 100%;
  // z-index: 1;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  color: #3b69d2;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%) rotate(-45deg);
  
`;

const MingButton = styled.button`

  background-color: #232940;
  color: #8a91ab;

  border: none;

  :disable {
    background-color: #232940;
    color: #8a91ab;
  }
  :hover {
    cursor: not-allowed;
    opacity: .87;
  }

    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

   height: 44px;
  
   border-radius: 5px;
   font-weight: 700;
   font-size: 19px;

   transition: opacity .15s ease-in;
`;

const StyledLockImgNode = styled.img`
  margin-left: 16px;
  vertical-align: middle;
  height: 13;
  width: 11;
  fill: none;  
`;
//
// Main component

export const NFTs = () => {
  const isSmallScreen = useMobileCheck('800px');
  const isMediumScreen = useMobileCheck('1080px');
  const m: boolean = (window as any).mobileCheck();
  const [value, setValue] = React.useState('Polygon Mainnet');
  const [mintNum, setNintNum] = React.useState(1);
  return (
    <PageTemplate
      title={'NFTs'}
    >
    <RainbowBackground >
      <MainContainer>
        <HeadContainer>
          <StyledHeadLeft>
            <Heading
              level={m ? 1 : 2}
              size={m ? 'medium' : 'large'}
              className="my10"
              color='#f8f8f0'
            >
              Mint Playable EverIsland NFTs
            </Heading>
            <Spacer size="md"/>
            <Text color='#d7def9' size='18px'>Mint your own isLand, Farmers and Tool&Pet NFTs which can be played in the game once Everisland is launched.</Text>
          </StyledHeadLeft>
          <StyledHeadRight>
            <StyledNftImgNode src={NftsJPG}></StyledNftImgNode>
          </StyledHeadRight>
        </HeadContainer>
        <ContentContainer className={m ? undefined : 'pt2'}>
          <Card height="auto" width="auto" background="#177090da" >
            <StyledCardBody pad="medium">
              <StyledDetailItem>
                  <StyledPartnerItemImg src= {IslandPNG}/>
                  <StyledRibbonText>Upcoming</StyledRibbonText>
                  <StyledItemTitle>Island&Plots</StyledItemTitle>
                  <StyledItemContent>Island is the primary NFT asset in Everisland.Every island have 9 plots NFTs that can be built and staked by the owner.</StyledItemContent>
                  <MingButton
                    className="py0"
                    disabled={true}
                  >
                    Coming Soon
                    <StyledLockImgNode src={LockSVG}></StyledLockImgNode>
                  </MingButton>
              </StyledDetailItem>
            </StyledCardBody>
          </Card>
          <Card height="auto" width="auto" background="#177090da" >
            <StyledCardBody pad="medium">
              <StyledDetailItem>
                  <StyledPartnerItemImg src= {FarmersPNG}/>
                  <StyledRibbonText>Upcoming</StyledRibbonText>
                  <StyledItemTitle>Farmers</StyledItemTitle>
                  <StyledItemContent>Farmers produce a variety of materials, farmers have different types of work, strength and productivity.</StyledItemContent>
                  <MingButton
                    className="py0"
                    disabled={true}
                  >
                    Coming Soon
                    <StyledLockImgNode src={LockSVG}></StyledLockImgNode>
                  </MingButton>
              </StyledDetailItem>
            </StyledCardBody>
          </Card>
          <Card height="auto" width="auto" background="#177090da" >
            <StyledCardBody pad="medium">
            <StyledDetailItem>
              <StyledPartnerItemImg src= {ToolPetPNG}/>
              <StyledRibbonText>Upcoming</StyledRibbonText>
              <StyledItemTitle>Tools&Pets</StyledItemTitle>
              <StyledItemContent>Tools can help farmers be more productive. Pets bring food to farmers.They can greatly improve work efficiency.</StyledItemContent>
              <MingButton
                className="py0"
                disabled={true}
              >
                Coming Soon
                <StyledLockImgNode src={LockSVG}></StyledLockImgNode>
              </MingButton>
            </StyledDetailItem>   
            </StyledCardBody>
          </Card>             
        </ContentContainer>
      </MainContainer>
    </RainbowBackground>
    </PageTemplate>
  );
};

export default NFTs;
