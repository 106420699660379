import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Box, DropButton } from 'grommet';
import { Menu, Language } from 'grommet-icons';

import LogoPng from '../assets/images/logo.png';

import { trimString } from '../utils/trimString';
import { Dot } from './Dot';
import { Link } from './Link';
import { Text } from './Text';
import { Button } from './Button';
//import { routesEnum } from '../Routes';
//import { Heading } from './Heading';
import { Heading as H } from 'grommet';

import useMobileCheck from '../hooks/useMobileCheck';

const RainbowBackground = styled(Box)`
  background-image: ${p => `linear-gradient(to right, ${p.theme.rainbow})`};
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const Logo = styled.img`
  height: 56px;
  width: 98px;
`;


const NavBarLinks = styled.div`
  display: flex;
  @media only screen and (max-width: 1080px) {
    .secondary-link {
      display: none;
    }
  }
`;

const MenuDropdown = styled(DropButton)`
  padding: 12px 8px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border: none;
  :hover {
    border: none;
    box-shadow: none;
  }
`;

const DotDropdown = styled(DropButton)`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  :hover {
    transition: transform 0.2s;
    transform: scale(1.1);
  }
`;

const DropdownLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const Card = styled.div``;

const NavLinksRight = styled.div`
  display: flex;
  align-items: center;
`;

const BarLinkText = styled(H)`
  font-family: 'Lufga';
  color: ${p => (p.color ? p.color : p.theme.blue.dark)};
  :not(.no-padding) {
    padding: 0 12px;
    white-space: nowrap;
  }
  margin: 0;
  max-width: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  text-shadow: 4px 4px 5px rgb(0 0 0 / 25%);
  
`;

const _AppBar = ({ location }: RouteComponentProps) => {
    /*
  const {
    active: walletConnected,
    account,
    chainId,
  }: web3ReactInterface = useWeb3React<Web3Provider>();
  */
  let network;
  let networkAllowed = false;


  const pathname: string = React.useMemo(() => location.pathname, [
    location.pathname,
  ]);


  const mobile = useMobileCheck('1080px');

  const networkName =  'mainnet';

  return (
    <RainbowBackground
      tag="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ left: 'medium', right: 'small', vertical: 'small' }}
      elevation="medium"
      style={{ zIndex: 1 }}
    >
      <NavBarLinks>
        <Link to='/' className="mr30">
          <Logo src={LogoPng} alt="everisland-logo" />
          {!mobile && (
            <div className="flex flex-column center ml5">
              <BarLinkText
                level={4}
                margin="none"
                className="bar-link-text no-padding"
              >
              </BarLinkText>
            </div>
          )}
        </Link>
        
        <Link to='/' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
          >
            Home
          </BarLinkText>
        </Link>

        <Link to='/nfts' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
          >
            NFTs
          </BarLinkText>
        </Link>
        
        <Link to='https://docs.everisland.io' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
          >
            LITEPAPER
          </BarLinkText>
        </Link>
        
        <Link to='#' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
          >
            Marketplace
          </BarLinkText>
        </Link>
      </NavBarLinks>
      <NavLinksRight>
        <Link to='https://test.everisland.io' className="mx10">
          <Button 
            label={'play game'}
            disabled={true}
            size={'small'}
          />
        </Link>
        {mobile && (
          <MenuDropdown
            className="secondary-link"
            label={<Menu color="black" />}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <Card>
                <Box pad="large" className="mt0">
                  <DropdownLink to='/nfts'>
                    NFTS
                  </DropdownLink>
                  <DropdownLink to='https://docs.everisland.io'>
                    LITEPAPER
                  </DropdownLink>
                  <DropdownLink to='/#'>
                    MARKETPLACE
                  </DropdownLink>
                </Box>
              </Card>
            }
          />
        )}
        
      </NavLinksRight>
    </RainbowBackground>
  );
};

export const AppBar = withRouter(_AppBar);
