import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Card,CardBody,Select,Box } from 'grommet';

import { Text } from '../../components/Text';

import useMobileCheck from '../../hooks/useMobileCheck';


import { Heading } from 'grommet';
import Spacer from "../../components/Spacer";
import { Button } from '../../components/Button';


import DotsPNG from '../../assets/images/pattern-dots.png';
import RoadmapTitleSVG from '../../assets/svg/roadmap-title.svg';
import RoadmapSVG from '../../assets/svg/roadmap.svg';
import IslandNftJPG from '../../assets/images/islandnft.jpg';

import PlusPNG from '../../assets/images/+.png';
import SubPNG from '../../assets/images/-.png';


interface mobile {
  isMobile: boolean;
}

const RainbowBackground = styled.div`
  min-width: 100%;
  overflow: hidden;
`;
const MainContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  padding-top: 60px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto 5%;

`;

const ContentContainer = styled.div`

  padding-top: 40px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 2px solid #6cfefe;
  border-radius: 12px;
  box-shadow: inset 1px 1px 13px 0 rgba(108,254,254,.3),0 1px 20px 0 rgba(108,254,254,.4);
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    padding: 6px;

  }
`;

const StyledContentLeft = styled.div`
  width: 46%;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    width: 100%;
  }
`;

const Styledneontext = styled.div`
  padding-bottom: 20px;
  font-family: 'Lufga',sans-serif;
  color: #3dfcfc;
  font-size: 24px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 -1px 20px #6cfefe;
`;

const StyledContentRight = styled.div`
  width: 46%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    width: 100%;

  }
`;

const StyledBulletList = styled.ul`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
`;

const StyledBulletItem = styled.li`
  display: list-item;
  ::marker {
    color: #f8f8f0;
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
`;

const StyledItemContent = styled.p`
  font-family: 'Lufga';
  font-size: 20px;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  background-color: transparent;
  transition: color .3s;
  color: #00b0f0;
  font-weight: 700;
  text-decoration: none;
  text-shadow: 0 1px 10px rgba(108,254,254,.22);
}
`

const StyledFlexBox = styled.div`
  color: #f8f8f0;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  margin-bottom: .5rem;
  border: 1px solid #7962FBd0;
  border-radius: 4px;
  :after, :before {
    border: 1 solid #e5e7eb;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246/0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}
`;

const StyledCardBody = styled(CardBody)`
  background-image: url(${DotsPNG});
  background-size: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
`;

const MingButton = styled(Button)`
  height: 50px;
  background-color: #7962FB;
  border: #7962FB;
  min-width: 80%;
`;

const StyledIslandNftImg = styled.img`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  // border: 8px solid #7962FBf0;
  max-width: 385px;
  max-height: 385px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    max-width: 95%;
    max-height: 95%;

  }
  
`;

const StyledIslandNftBuy = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledIslandNftLeft = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 50%;
  align-items: center;
  // justify-content: center;
  background-color: #7962FB;
  border-radius: 8px;
  padding: 0px 15px 0px 15px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    width: 100%;
  }
`;

const StyledIslandNftNum = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f0f0f0;
  color: #000000;
  border-radius: 4px;
  padding: 0px 20px 0px 20px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 70%;
`;

const StyledButton = styled.button`
  outline: none;
  border: none;
  padding: 1px 3px;
  background-color: transparent;
  transition: all .2s ease-out;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    transform: scale(1.1);
  }

  &:active {
    transform: translateY(5px);
  }
`;

const StyledMediaImgNode = styled.img`
  height: 100%;
  margin: auto;
  width: 25px;
  
`;

const StyledIslandNftRight = styled.div`
  width: 49%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #7962FB;
  border-radius: 8px;
  padding: 0px 15px 0px 15px;
  background-image: linear-gradient(90deg,rgba(75,50,116,.0),rgba(24,106,139,.8));
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    width: 100%;
    margin-top: 10px;
  }
`;

const StyledIslandChain = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    justify-content: center;
  }
`;

const StyledHeading = styled(Heading)`

  background-image: linear-gradient(270deg,#46c6a8,#cae959);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  
`;

const StyledSelect = styled(Select)`
  color: #7962FB;
  font-weight: 700;
  font-size: '18px';
  // border: 2px solid #6cfefe;
  
`;
//
// Main component

export const Mint = () => {
  const isSmallScreen = useMobileCheck('800px');
  const isMediumScreen = useMobileCheck('1080px');
  const m: boolean = (window as any).mobileCheck();
  const [value, setValue] = React.useState('Polygon Mainnet');
  const [mintNum, setNintNum] = React.useState(1);
  return (
    <RainbowBackground >
      <MainContainer>
        <StyledHeading
          level={ 2}
          size={'medium'}
          className="my25"
          color='#f8f8f0'
        >
          Mint EverIsland NFTs
        </StyledHeading>
        <ContentContainer className={m ? undefined : 'pt2'}>
            <StyledContentLeft>
              <Styledneontext>Learn More Islander Benefits</Styledneontext>
              <StyledBulletList>
              <StyledBulletItem>
                  <StyledItemContent>The core assets of island&lands nfts are all on Ethereum, and the island owner decides which EVM support chain to start the game</StyledItemContent>
                </StyledBulletItem>
                <StyledBulletItem>
                  <StyledItemContent>EverIsland NFTs get private club memberships and other benefits, which are certified by Islander club</StyledItemContent>
                </StyledBulletItem>
                <StyledBulletItem>
                  <StyledItemContent>Airdrop 9 plots per island for sale/rent</StyledItemContent>
                </StyledBulletItem>
                <StyledBulletItem>
                  <StyledItemContent>The islander automatically gets 2% of the island's output income</StyledItemContent>
                </StyledBulletItem>
                <StyledBulletItem>
                  <StyledItemContent>Islanders enjoy IGO priority</StyledItemContent>
                </StyledBulletItem>
                <StyledBulletItem>
                  <StyledItemContent>When "Metaverse island" goes online, the value of the island increases</StyledItemContent>
                </StyledBulletItem>
              </StyledBulletList>
            </StyledContentLeft>

            <StyledContentRight>
              <StyledFlexBox>200/200 genesis NFTs left</StyledFlexBox>
              <Card height="auto" width="auto" background="#177090da" >
                <StyledCardBody pad="medium">
                  <StyledIslandNftImg src={IslandNftJPG}></StyledIslandNftImg>
                  <StyledIslandNftBuy>
                    <StyledIslandNftLeft> 
                      <p>QTY</p>
                      <StyledButton onClick={()=>{
                        if (mintNum>=2){
                          setNintNum(mintNum-1)
                        }
                      }}>
                        <StyledMediaImgNode src={SubPNG}></StyledMediaImgNode>
                      </StyledButton>
                      <StyledIslandNftNum>
                        <Text color='#000000'>{mintNum}</Text>
                      </StyledIslandNftNum>
                      <StyledButton onClick={()=>{
                        if (mintNum==1){
                          setNintNum(mintNum+1)
                        }
                      }}>
                        <StyledMediaImgNode src={PlusPNG}></StyledMediaImgNode>
                      </StyledButton>
                    </StyledIslandNftLeft>
                    <StyledIslandNftRight>
                      <p>Price</p>
                      <p>1.00ETH</p>
                    </StyledIslandNftRight>
                  </StyledIslandNftBuy>
                  
                  <StyledIslandChain>
                    <Text color='#f8f8f0' size='18px'>*Choose game chain:</Text>
                    <StyledSelect
                      size= 'small'
                      options={['Polygon Mainnet', 'BSC Mainnet', 'Seele Mainnet']}
                      value={value}
                      onChange={({ option }) => setValue(option)}
                    />
                  </StyledIslandChain>
                    <MingButton
                    className="py0"
                    label={'Coming Soon'}
                    disabled={true}
                  />
                </StyledCardBody>
              </Card>
            </StyledContentRight>                 
        </ContentContainer>
      </MainContainer>
    </RainbowBackground>
  );
};
