import React from 'react';
import styled from 'styled-components';
// @ts-ignore


import useMobileCheck from '../../hooks/useMobileCheck';


import RoadmapTitlePNG from '../../assets/images/roadmap-title.png';
import RoadmapPNG from '../../assets/images/roadmap.png';
import RoadmapSmallPNG from '../../assets/images/roadmap-small.png';

interface mobile {
  isMobile: boolean;
}

const RainbowBackground = styled.div`
  min-width: 100%;
  overflow: hidden;
  min-height: ${(p: mobile) => p.isMobile && 'calc(100vh - 20px)'};
  
`;
const MainContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.larger};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${p => p.theme.screenSizes.large}) {
    padding: ${(p: mobile) => (p.isMobile ? '20px' : '0 60px 50px')};
    min-height: ${(p: mobile) => (p.isMobile ? '100vh' : undefined)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  position: relative;
  max-width: 1000px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
`;



const StyledTitleImgNode = styled.img`
  height: 111px;
  width: 374px;
  margin: 20px 0px 20px 0px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.small}) {
    width: 288px;
  }
`;

const StyledRoadmapTitle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  top: 60px;
  font-size: 36px;
  font-family: 'BoldenVan', Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  color: #f8f8f0;
`;

const RoadmapContainer = styled.div`
  position: relative;
  max-width: 1000px;
  min-width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  
`;

const StyledRoadmapImgNode = styled.img`
  height: auto;
  margin: auto;
  width: 100%;
  @media only screen and (max-width: ${p => p.theme.screenSizes.small}) {
    width: 80px;
    margin: 0;
  }
  
`;

const StyledRoadmapPhase1 = styled.div`
  display: flex;
  position: absolute;
  top: 40%;
  left: -14%;
  transform: translateZ(0);
  opacity: 1;
  flex-direction: column;
  text-align: right;
  @media only screen and (max-width: ${p => p.theme.screenSizes.small}) {
    top: 8%;
    left: 26%;
    text-align: left;
  }
`;

const StyledRoadmapPhase2 = styled.div`
  display: flex;
  position: absolute;
  top: 6%;
  left: 55%;
  transform: translateZ(0);
  opacity: 1;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: ${p => p.theme.screenSizes.small}) {
    top: 41%;
    left: 26%;
    text-align: left;
  }
`;

const StyledRoadmapPhase3 = styled.div`
  display: flex;
  position: absolute;
  top: 10%;
  left: 88%;
  transform: translateZ(0);
  opacity: 1;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: ${p => p.theme.screenSizes.small}) {
    top: 56%;
    left: 26%;
  }
`;

const StyledRoadmapPhase4 = styled.div`
  display: flex;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateZ(0);
  opacity: 1;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: ${p => p.theme.screenSizes.small}) {
    top: 81%;
    left: 26%;
  }
`;



const StyledRoadmapPhaseHead = styled.div`
  
  white-space: nowrap;
  font-size: 22px;
  font-family: 'Lufga', Arial, sans-serif;
  font-weight: 300;
  color: #f8f8f0;
`;

const StyledRoadmapPhaseContent = styled.div`

  display: flex;
  flex-direction: column;
  margin-top: 6px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1.2;
  min-height: 30px;
  font-family: 'Lufga', Arial, sans-serif;
  font-weight: 300;
  color: #d7def9;
`;

//
// Main component

export const Roadmap = () => {
  const isSmallScreen = useMobileCheck('800px');
  const isMediumScreen = useMobileCheck('1080px');
  const m: boolean = (window as any).mobileCheck();
  return (
    <RainbowBackground isMobile={m}>
      <MainContainer isMobile={m}>
            <ContentContainer className={m ? undefined : 'pt2'}>
                <InfoContainer>
                    <StyledTitleImgNode src={RoadmapTitlePNG} alt="roadmap title"></StyledTitleImgNode>
                    <StyledRoadmapTitle>ROAD MAP</StyledRoadmapTitle>
                    <RoadmapContainer>
                      <StyledRoadmapImgNode src={m?RoadmapSmallPNG:RoadmapPNG} alt="roadmap"></StyledRoadmapImgNode>
                      <StyledRoadmapPhase1>
                        <StyledRoadmapPhaseHead>Q2-2022</StyledRoadmapPhaseHead>
                        <StyledRoadmapPhaseContent>
                          <span>Island Kickstart</span>
                          <span>Launch Simulate Game</span>
                          <span>Marketplace</span>
                        </StyledRoadmapPhaseContent>
                      </StyledRoadmapPhase1>
                      <StyledRoadmapPhase2>
                        <StyledRoadmapPhaseHead>Q3-2022</StyledRoadmapPhaseHead>
                          <StyledRoadmapPhaseContent>
                            <span>DAO Token</span>
                            <span>Community Governance</span>
                            <span>Game Upgrade</span>
                        </StyledRoadmapPhaseContent>
                      </StyledRoadmapPhase2>
                      <StyledRoadmapPhase3>
                        <StyledRoadmapPhaseHead>Q4-2022</StyledRoadmapPhaseHead>
                          <StyledRoadmapPhaseContent>
                            <span>Metaverse Game Alpha</span>
                            <span>UGC(NFT Build) Studio</span>
                        </StyledRoadmapPhaseContent>
                      </StyledRoadmapPhase3>
                      <StyledRoadmapPhase4>
                        <StyledRoadmapPhaseHead>Q1-2023</StyledRoadmapPhaseHead>
                          <StyledRoadmapPhaseContent>
                            <span>Metaverse Game Launch</span>
                            <span>Marketplace V2</span>
                        </StyledRoadmapPhaseContent>
                      </StyledRoadmapPhase4>
                    </RoadmapContainer>
                </InfoContainer>
            </ContentContainer>
      </MainContainer>
    </RainbowBackground>
  );
};
