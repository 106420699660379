import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Heading } from 'grommet';

import { Text } from '../../components/Text';

import useMobileCheck from '../../hooks/useMobileCheck';

// import { Heading } from '../../components/Heading'
import Spacer from "../../components/Spacer";



import RoadmapTitleSVG from '../../assets/svg/roadmap-title.svg';
import RoadmapSVG from '../../assets/svg/roadmap.svg';
import PatternconfettiJPG from '../../assets/images/pattern-waves-small.jpg';

import Partnerlogo1PNG from '../../assets/images/detail1.jpg';
import Partnerlogo2PNG from '../../assets/images/detail2.jpg';
import Partnerlogo3PNG from '../../assets/images/detail3.jpg';
import Partnerlogo4PNG from '../../assets/images/detail4.jpg';
import Partnerlogo5PNG from '../../assets/images/detail5.jpg';
import Partnerlogo6PNG from '../../assets/images/detail6.jpg';


interface mobile {
  isMobile: boolean;
}

const RainbowBackground = styled.div`
  min-width: 100%;
  overflow: hidden;
`;
const MainContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.huge};
  width: 100%;
  padding-top: 60px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto 5%;

`;

const ContentContainer = styled.div`

  padding-top: 40px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 5%;
  column-gap: 5%;

`;

const StyledDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledItemTitle = styled.p`
  font-family: 'BoldenVan', Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-top: 36px;
  margin-bottom: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #EDD333;

`;

const StyledItemContent = styled.p`
  font-family: 'Lufga';
  font-size: 16px;
  font-style: normal;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: #d7def9;
  max-width: 385px;
  margin-bottom: 40px;
`



const StyledPartnerItemImg = styled.img`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border-radius: 8px;
  max-width: 385px;
  max-height: 385px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    max-width: 95%;
    max-height: 95%;

  }
  
`;

const StyledHeading = styled(Heading)`

  background-image: linear-gradient(270deg,#46c6a8,#cae959);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  
`;
//
// Main component

export const GameDetail = () => {
  const isSmallScreen = useMobileCheck('800px');
  const isMediumScreen = useMobileCheck('1080px');
  const m: boolean = (window as any).mobileCheck();
  return (
    <RainbowBackground >
      <MainContainer>
        <StyledHeading
          level={ 2}
          size={'medium'}
          className="my25"
          color='#f8f8f0'
          
        >
          First Launch Game
        </StyledHeading>
        <ContentContainer className={m ? undefined : 'pt2'}>
            
            <StyledDetailItem>
                <StyledPartnerItemImg src= {Partnerlogo1PNG}/>
                <StyledItemTitle>Introduction</StyledItemTitle>
                <StyledItemContent>The first launch game is a simulated web game, which is used to generate resources, and the resources will be used for the construction of the metaverse island game later.</StyledItemContent>
            </StyledDetailItem>
            <StyledDetailItem>
                <StyledPartnerItemImg src= {Partnerlogo2PNG}/>
                <StyledItemTitle>Minting</StyledItemTitle>
                <StyledItemContent>Each farmer is a unique NFT consisting of the following rare combinations: Type, Category, Power, Capacity. Buy farmers to work on harvesting materials for island upgrades.</StyledItemContent>
            </StyledDetailItem>
            <StyledDetailItem>
                <StyledPartnerItemImg src= {Partnerlogo3PNG}/>
                <StyledItemTitle>Farming</StyledItemTitle>
                <StyledItemContent>Reasonable allocation of resources to optimize farmers' production. Use good tools and pets, free your hands and increase productivity.</StyledItemContent>
            </StyledDetailItem>
            <StyledDetailItem>
                <StyledPartnerItemImg src= {Partnerlogo4PNG}/>
                <StyledItemTitle>Technology Tower</StyledItemTitle>
                <StyledItemContent>Upgrade specialization skills in technology towers, increase farmer productivity, and create specialized tools.Donate resources, help the island owner to upgrade the island, open the skill level.</StyledItemContent>
            </StyledDetailItem>
            <StyledDetailItem>
                <StyledPartnerItemImg src= {Partnerlogo5PNG}/>
                <StyledItemTitle>Dock</StyledItemTitle>
                <StyledItemContent>Resources are delivered to the blockchain through the dock. The dock is upgraded by the island owner to improve the transportation capacity.</StyledItemContent>
            </StyledDetailItem>
            <StyledDetailItem>
                <StyledPartnerItemImg src= {Partnerlogo6PNG}/>
                <StyledItemTitle>Exchange</StyledItemTitle>
                <StyledItemContent>Farmers are Erc721 NFT, materials, tools, pets are Erc1155 NFT. They are all allowed to be traded in the decentralized market. Trade items, combine reasonable resources, and efficiently develop islands.</StyledItemContent>
            </StyledDetailItem>
                  
        </ContentContainer>
      </MainContainer>
    </RainbowBackground>
  );
};
