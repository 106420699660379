import React from 'react';
import styled from 'styled-components';
import { Card,CardBody } from 'grommet';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
// @ts-ignore
import Animate from 'animate.css-react';
import charchiefPNG from '../../assets/images/char_chief.png';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import useMobileCheck from '../../hooks/useMobileCheck';
import { routesEnum } from '../Routes';

import Spacer from "../../components/Spacer";

import DotsPNG from '../../assets/images/pattern-dots.png';

import Game1JPG from '../../assets/images/game1.jpg';
import Game2JPG from '../../assets/images/game2.jpg';
import SeabenchJPG from '../../assets/images/seabench.jpg';
import PatternconfettiJPG from '../../assets/images/pattern-waves-small.jpg';
import TransferdecorationSVG from '../../assets/svg/transfer-decoration.svg';
interface mobile {
  isMobile: boolean;
}

const RainbowBackground = styled.div`
  min-width: 100%;
  overflow: hidden;
  min-height: ${(p: mobile) => p.isMobile && 'calc(100vh - 20px)'};
`;
const MainContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.larger};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${p => p.theme.screenSizes.large}) {
    padding: ${(p: mobile) => (p.isMobile ? '10px' : '0 60px 50px')};
    min-height: ${(p: mobile) => (p.isMobile ? '100vh' : undefined)};
  }
`;
const ResponsiveContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.large};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${(p: mobile) => (p.isMobile ? '100%' : undefined)};
  > div.is-mobile {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 10px);
  }
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const HeadContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.medium};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    
  }
`;

const InfoContainer = styled.div`
  max-width: ${p => p.theme.screenSizes.large};
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    max-width: ${p => p.theme.screenSizes.large};
    padding-top: 40px;
    width: 100%;
  }
`;

const StyledHeadCardBody = styled(CardBody)`
  background-image: url(${DotsPNG});
  background-size: 10px 10px;
}
`;

const StyledCardBody = styled(CardBody)`
  background-image: url(${PatternconfettiJPG});
  background-size: 127px 170px;
  display: flex;
  flex-direction: column;
}
`;

const InfoContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    flex-direction: column;
    justify-content: center;
    // padding-bottom: 280px;
  }
`;

const StyledCardGreenBody = styled(CardBody)`
  background-color: #fdfad8;
  justify-content: center;
}
`;

const StyledCardWhiteBody = styled(CardBody)`
  background-color: #f8f8f0;
  justify-content: center;
}
`;


const PhotoStack1 = styled.div`
  position: relative;
  right:0;
  margin: auto;
  max-width: none;
  width: 100%
  justify-content: center;
  align-items: center;
  
`;

const StyledIslandNftImg = styled.img`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border: 8px solid #f8f8f8f0;
  // object-fit: contain;
  max-width: 100%;
  width: 100%
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    // max-width: 95%;
    // max-height: 95%;

  }
  
`;

const PhotoStack = styled.div`
  position: relative;
  right:0;
  margin: auto;
  max-width: none;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    flex-basis: 100%;
    width: 100%
  }
`;

const PhotoStackItem = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.4s var(--cubic-bezier-in),opacity 0.4s var(--cubic-bezier-in);
`;

const StyledInfoImgNode = styled.img`
  max-width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border: 10px solid #f8f8f0;
  object-fit: contain;
  transform: translate(2%, -50%) rotate(0deg);
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    transform: translate(2%, 10%) rotate(0deg);
  }
`;

const StyledInfoImgNode1 = styled.img`
  max-width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border: 10px solid #f8f8f0;
  object-fit: contain;
  transform: translate(-6%, -70%) rotate(10deg);
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    transform: translate(-6%, -10%) rotate(10deg);
  }
`;

const StyledInfoImgNode2 = styled.img`
  max-width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 50%);
  border: 10px solid #f8f8f0;
  object-fit: contain;
  transform: translate(-5%, -30%) rotate(-5deg);
  @media screen and (max-width: ${({ theme }) => theme.screenSizes.small}) {
    transform: translate(-5%, 30%) rotate(-5deg);
  }
`;

const StyledRuleGreen = styled.div`
    
    position: relative;
    padding: 26px;
    background-image: url(${TransferdecorationSVG});
    background-size: auto auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;

`;
//
// Main component

export const Info = () => {
  const isSmallScreen = useMobileCheck('800px');
  const isMediumScreen = useMobileCheck('1080px');
  const m: boolean = (window as any).mobileCheck();
  return (
    <RainbowBackground isMobile={m}>
      <MainContainer isMobile={m}>
        <ResponsiveContainer isMobile={m}>
            <ContentContainer className={m ? undefined : 'pt2'}>
              <HeadContainer>
                    <Card height="auto" width={m?"100%":"100%"} background="#177090da" >
                      <StyledHeadCardBody pad="medium">
                          <Heading
                            level={m ? 1 : 2}
                            size={m ? 'medium' : 'large'}
                            className="my10"
                            color='#f8f8f0'
                          >
                            Welcome To EverIsland
                          </Heading>
                          <Text className="mt10" color='#d7def9' center={true}>
                            EverIsland is a most fantastic Metaverse Game.All in-game items and creatures are blockchain based tokens and NFTs.EverIsland is inspired by classics like Pokemon, Zelda, Animal Crossing, & the Studio Ghibli films.
                          </Text>
                      </StyledHeadCardBody>
                    </Card>
                </HeadContainer>  
                <InfoContainer>    
                  <ScrollAnimation animateIn="fadeIn" delay={150} animateOnce>
                      <Card height="auto" width={m?"100%":"100%"}  >
                        <StyledCardBody pad="medium">
                          <InfoContentContainer>
                            <Card height="auto" width={m?"100%":"50%"}  >
                              <StyledCardGreenBody pad="medium">
                                <Heading level={m ? 1 : 2} size={ 'small'} className="my10" color='#6b5c43'>
                                  Build your island whatever your want
                                </Heading>
                                <Text className="mt10" color='#6b5c43' size='18px'>
                                  We will work with you to create a metaverse blockchain island, creating and customizing island communities.
                                </Text>
                                <Text className="mt10" color='#6b5c43' size='18px'>
                                  Play To Earn:The first stage is to start the simulation web game, play to get the basic materials for island construction.
                                </Text>
                                <Text className="mt10" color='#6b5c43' size='18px'>
                                  DAO:Then, release the EverIsland DAO Token for community governance.
                                </Text>
                                <Text className="mt10" color='#6b5c43' size='18px'>
                                  Create And Earn:Finally, open your metaverse island, build island,show your NFTs and publish events,invite friends to enhance value.
                                </Text>
                                <StyledRuleGreen/>
                              </StyledCardGreenBody>
                            </Card>
                            {m?(
                               <PhotoStack1>
                                 <Spacer/>
                                  <StyledIslandNftImg src={SeabenchJPG}
                                  alt="seabench">
                                  </StyledIslandNftImg>
                                  <StyledIslandNftImg src={Game1JPG}
                                  alt="seabench">
                                  </StyledIslandNftImg>
                              </PhotoStack1>
                            ):(
                              <PhotoStack>
                                <PhotoStackItem>
                                  <StyledInfoImgNode2 src={Game2JPG}
                                    alt="seabench">
                                  </StyledInfoImgNode2>
                                </PhotoStackItem>
                                <PhotoStackItem>
                                  <StyledInfoImgNode1 src={Game1JPG}
                                    alt="seabench">
                                  </StyledInfoImgNode1>
                                </PhotoStackItem>
                                <PhotoStackItem>
                                  <StyledInfoImgNode src={SeabenchJPG}
                                    alt="seabench">
                                  </StyledInfoImgNode>
                                </PhotoStackItem>
                              </PhotoStack>
                            )}
                            
                          </InfoContentContainer>
                      </StyledCardBody>
                    </Card>
                  </ScrollAnimation>
                </InfoContainer>
            </ContentContainer>
        </ResponsiveContainer>
      </MainContainer>
    </RainbowBackground>
  );
};
