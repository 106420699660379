import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Heading } from './Heading';
import { Link } from './Link';
import { Text } from './Text';

import { Button } from './Button';

import BumpTopYellowPNG from '../assets/images/bump-top-yellow.png';

import ShellPNG from '../assets/images/shell.png';
import Shell2PNG from '../assets/images/shell-2.png';

import DiscordSVG from '../assets/svg/discord.svg';
import TelgramSVG from '../assets/svg/telgram.svg';
import TwitterSVG from '../assets/svg/twitter.svg';
import FacebookSVG from '../assets/svg/facebook.svg';
import OpenseaSVG from '../assets/svg/opensea.svg';
import YoutubeSVG from '../assets/svg/youtube.svg';

const Rhino = styled.span`
  font-size: 20px;
`;

const RainbowBackground = styled.div`
  min-width: 100%;
  filter: drop-shadow(0px 4px 10px rgba(0,0,0,0.3));
  // margin-top: 60px;
  background-color: #f8eebc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  &:before {
    background-image: url(${BumpTopYellowPNG});
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: 50% 0%;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: -9px;
    width: 100%;
  }
  
`;

const FooterStyles = styled.div`
  max-width: ${p => p.theme.screenSizes.large};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3rem 1rem;
  flex-direction: column;
  align-items: center;
  margin: 0 0;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
  @media screen and (max-width: 960px) {
    .cta-button {
      display: none;
    }
  }

  @media screen and (max-width: 518px) {
    .extra-links {
      margin-top: 1rem;
    }
  }
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const ButtonLink = styled(Link)`
  width: fit-content;
  padding: 0;
`;

const StyledShell1 = styled.img`
  left: calc(50% - 530px);
  position: absolute;
  top: 73px;
}
`;

const StyledShell2 = styled.img`
  left: calc(50% - 439px);
  position: absolute;
  top: 160px;
`;

const StyledShell3 = styled.img`
  left: calc(50% + 407px);
  position: absolute;
  top: 150px;
  width: 66px;
`;

const StyledButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  transition: all .2s ease-out;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    transform: scale(1.1);
  }

  &:active {
    transform: translateY(5px);
  }
`;

const StyledMediaImgNode = styled.img`
  height: 44;
  margin: auto;
  width: 100%;
  
`;

export const Footer = () => {
  const { pathname } = useLocation();

  return (
    <RainbowBackground>
      <StyledShell1 src={ShellPNG}/>
      <StyledShell2 src={Shell2PNG}/>
      <StyledShell3 src={ShellPNG}/>
      <FooterStyles>
        <Heading
          level={3}
          size={'medium'}
          className="my10"
          color='#1B75BC'
        >
          Follow US!
        </Heading>
        <MediaContainer>
          <a href="https://discord.gg/m5cCZkjp" >
            <StyledButton>
              <StyledMediaImgNode src={DiscordSVG}></StyledMediaImgNode>
            </StyledButton>
          </a>
          <a href="https://t.me/FantaCore" >
            <StyledButton>
              <StyledMediaImgNode src={TelgramSVG}></StyledMediaImgNode>
            </StyledButton>
          </a>
          <a href="https://twitter.com/island_ever" >
            <StyledButton>
              <StyledMediaImgNode src={TwitterSVG}></StyledMediaImgNode>
            </StyledButton>
          </a>
          { /*
          <a href="https://discord.com/invite/chumbi" >
            <StyledButton>
              <StyledMediaImgNode src={FacebookSVG}></StyledMediaImgNode>
            </StyledButton>
          </a>
          <a href="#" >
            <StyledButton>
              <StyledMediaImgNode src={OpenseaSVG}></StyledMediaImgNode>
            </StyledButton>
          </a>
          <a href="#" >
            <StyledButton>
              <StyledMediaImgNode src={YoutubeSVG}></StyledMediaImgNode>
            </StyledButton>
          </a> */}
        </MediaContainer>
        <Text className="mt10" color='#6b5c43' size='18px'>
          Copyright © EverIsland Tech Ltd
        </Text>
      </FooterStyles>
      
    </RainbowBackground>
  );
};
