import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, withRouter } from 'react-router-dom';

import { supportedLanguages } from '../intl';

import HomePage from "./Home";

import {NFTs} from "./NFTs";
import NotFoundPage from "./NotFound";


import ScrollToTop from '../utils/ScrollToTop';

type RouteType = {
  path: string;
  component: FunctionComponent;
  exact?: boolean;
};

export enum routesEnum {
  NFTsPage = '/nfts',
  checklistPage = '/checklist',
  topUpPage = '/topup',
  ValidatorsPage = '/validators',
  ValidatorDetailPage = '/validators/:address',
  StakePage = '/stake',
  HomePage = '/',
  notFoundPage = '/*',
  languagesPage = '/languages',
}
const routes: RouteType[] = [
  {
    path: routesEnum.NFTsPage,
    exact: true,
    component: NFTs,
  },

  { path: routesEnum.HomePage, exact: true, component: HomePage },
  // NOTE: this wildcard route must be the last index of the routes array
  { path: routesEnum.notFoundPage, component: NotFoundPage },
];

const localizeRoutes = (locale: String, routes: RouteType[]) => {
  return routes.map(route => {
    const languagePath = route.path.split('/')[1];
    const routeHasLangPath = supportedLanguages.includes(languagePath);
    if (routeHasLangPath || route.path === '/*') {
      return route;
    }
    const localizedRoute: RouteType = {
      path: `${route.path}`,
      exact: route.exact,
      component: route.component,
    };
    return localizedRoute;
  });
};

const _Routes = () => {

  const title = 'EverIsland';
  const description = 'EverIsland is a metaverse island.';
  return (
    <>
      <ScrollToTop>
        <Helmet>
          <html lang={'en'} />
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="twitter:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path= {routesEnum.NFTsPage} exact>
            <NFTs/>
          </Route>
          <Route path="/*">
            <NotFoundPage />
          </Route>
        </Switch>
      </ScrollToTop>
    </>
  );
};

export const Routes = withRouter(_Routes);
